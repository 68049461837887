@tailwind components;
@tailwind base;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Poppins:wght@400;500;600;700;800&family=Sora:wght@400;500;600&display=swap');

*, *::after, *::before {
	box-sizing: border-box;
	font-family: Poppins, san-serif;
	font-weight: 400,
}
body {
	@apply bg-black;
}
svg.circle {
	transform: rotate(-90deg);
	overflow: initial
}
circle {
	stroke-width: 10px;
	fill: none;
	position: relative;
	z-index: 1;
}
.viewSkills {
	background-image: linear-gradient(to right, #7c3aed, #d946ef);
	background-clip: text;
	color: transparent;
}
.project-title {
	padding-bottom: 4px;
	position: relative;
	text-decoration: none
}
.project-title.nav {
	padding-bottom: inherit;
	position: relative;
	text-decoration: none
}
.project-title::after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background: linear-gradient(to right,#7c3aed, #9333ea, #d946ef);
	height: 4px;
	border-radius: 4px;
	transition: ease-in-out 0.4s all;
}

